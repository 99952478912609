export default {
    namespaced: true,
    state: {
        showReturnTip: false,
        showH5SusBtn: true,
        marginLeft: (document.body.clientWidth-window.outerWidth)*0.71+'px',
        marginTop: -150+((window.outerHeight-window.screen.availHeight)*0.71)+'px',
        windowResize: false,
        clientWidth: 320,
        isMainStation: true,//当前路由是否需要主站的头部、底部
    },
    mutations: {
        setShowReturnTip(state,result) {
            state.showReturnTip = result;
        },
        setShowH5SusBtn(state,result) {
            state.showH5SusBtn = result;
        },
        setMarginLeft(state,result) {
            state.marginLeft = result;
        },
        setMarginTop(state,result) {
            state.marginTop = result;
        },
        setClientWidth(state,result) {
            state.clientWidth = result;
        },
        setWindowResize(state) {
            state.windowResize = !state.windowResize;
        },
        setIsMainStation(state, result) {
            state.isMainStation = Boolean(result);
        }
    }
};