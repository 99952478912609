<template>
  <v-row
      class="fill-height ma-0"
      align="center"
      justify="center"
  >
    <v-progress-circular
        indeterminate
        :size="size"
        :width="width"
        :color="color"
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: "ImgPlaceholder",
  props: {
    size: {
      type: String,
      default() {
        return "32";
      }
    },
    width: {
      type: String,
      default() {
        return "4";
      }
    },
    color: {
      type: String,
      default() {
        return "grey lighten-3";
      }
    },
  },
}
</script>

<style scoped>

</style>