export default {
    diyWindowResize(){
        if(document.createEvent) {
            var event = document.createEvent("HTMLEvents");
            event.initEvent("resize", true, true);
            window.dispatchEvent(event);
        } else if(document.createEventObject) {
            window.fireEvent("onresize");
        }
    }
}

export function debounce(func, wait, immediate) {
    let timeout

    return function(...args) {
        let context = this
        if (timeout) clearTimeout(timeout)
        if (immediate) {
            let callNow = !timeout
            timeout = setTimeout(function() {
                timeout = null
            }, wait)
            if (callNow) func.apply(context, args)
        } else {
            timeout = setTimeout(function() {
                func.apply(context, args)
            }, wait)
        }
    }
}


export function throttle(func, wait, duration) {
    let timeout
    let begin = new Date().getTime();
    return function(...args) {
        let context = this
        let current = new Date().getTime();
        if (timeout) clearTimeout(timeout)
        if (current-begin>=duration) {
            context.screenY = args[0].screenY
            console.log(args[0])
            func.apply(context, args)
            begin = current
        } else {
            timeout = setTimeout(function() {
                func.apply(context, args)
            }, wait)
        }
    }
}


export function browserVersion() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //Edge浏览器
    var isFirefox = userAgent.indexOf("Firefox") > -1; //Firefox浏览器
    var isOpera = userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1; //Opera浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Edge") == -1 && userAgent.indexOf("OPR") == -1; //Chrome浏览器
    var isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1 && userAgent.indexOf("Edge") == -1 && userAgent.indexOf("OPR") == -1; //Safari浏览器
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
        // return 'IE:7';
        return true;
        } else if (fIEVersion == 8) {
        // return 'IE:8';
        return true;
        } else if (fIEVersion == 9) {
        // return 'IE:9';
        return true;
        } else if (fIEVersion == 10) {
        // return 'IE:10';
        return true;
        } else {
        // return 'IE:6'; //IE版本<7
        return true; //IE版本<7
        }
    } else if (isIE11) {
        // return 'IE:11';
        return true;
    } else if (isEdge) {
        // return 'Edge:' + userAgent.split('Edge/')[1].split('.')[0];
        return false;
    } else if (isFirefox) {
        // return 'Firefox:' + userAgent.split('Firefox/')[1].split('.')[0];
        return false;
    } else if (isOpera) {
        // return 'Opera:' + userAgent.split('OPR/')[1].split('.')[0];
        return false;
    } else if (isChrome) {
        // return 'Chrome:' + userAgent.split('Chrome/')[1].split('.')[0];
        return false;
    } else if (isSafari) {
        // return 'Safari:' + userAgent.split('Safari/')[1].split('.')[0];
        return false;
    } else {
        // return -1; //不是ie浏览器
        return false; //不是ie浏览器
    }
}


export function browser() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    // var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    // var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //Edge浏览器
    // var isFirefox = userAgent.indexOf("Firefox") > -1; //Firefox浏览器
    // var isOpera = userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1; //Opera浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Edge") == -1 && userAgent.indexOf("OPR") == -1; //Chrome浏览器
    // var isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1 && userAgent.indexOf("Edge") == -1 && userAgent.indexOf("OPR") == -1; //Safari浏览器
    if (isChrome) {
        return 'isChrome'
    }
        else {

        return -1;
    }
}